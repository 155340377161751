// parent

jQuery(document).ready(function ($) {
    $.validator.setDefaults({
        highlight: function (element) {
            $(element).addClass('is-invalid').removeClass('is-valid');
        },
        unhighlight: function (element) {
            $(element).removeClass('is-invalid').addClass('is-valid');
        },
        errorElement: 'span',
        errorClass: 'help-block',
        errorPlacement: function (error, element) {
        }
    });

    $("form.needs-validation").validate({
        invalidHandler: function(event, validator) {
            // 'this' refers to the form
            var errors = validator.numberOfInvalids();
            if (errors) {
                $('#error-input').removeClass('d-none');
            } else {
                $('#error-input').addClass('d-none');
            }
        },
        submitHandler: function (form) {
            $('#error-input').addClass('d-none');
            var params = $(form).serializeArray();
            $.post($(form).data('handle'), params, function(data){
                if(data.success) {
                    if (data.link_url != undefined) {
                        $('#copy-link-url').val(data.link_url);
                        $('#copy-link').removeClass('d-none');
                    }
                    $('#success-input').removeClass('d-none');
                    $('form').remove();
                } else {
                    alert('Sorry, pending request could not be created');
                    if (data.errors.length > 0) {
                        $.each(data.errors, function(i, field) {
                            $('#' + field).addClass('error');
                        });
                    }
                }
            }, 'json');
        }
    });

    $('input[list]').on('input', function(){
        var val_datalist = $($(this).prop('list')).find("option[value='" + $(this).val() + "']").data('value');
        var namefield = $(this).data( 'name' );
        $('#' + namefield).val( val_datalist );
        $('#new_client').val( '' );
        if (val_datalist === undefined) {
            $('#new_client').val( $(this).val() );
        }
    });

    $('#button-copy').click(function() {
        var copyText = document.getElementById("copy-link-url");

        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        document.execCommand("copy");

        /* Alert the copied text */
        alert("Copied the text: " + copyText.value);
    });
}); // jQuery End
